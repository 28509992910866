import config from '@/config';
import authService from '@/services/auth';
import robotTypeService from '@/services/robot-type';
import vpnCustomerService from '@/services/vpn-customer';
import zerotierNetworksService from '@/services/zerotier-network';
import isEmpty from 'lodash/isEmpty';
import * as http from '@/plugins/http';
import vm from '@/main';
import { Signer } from '@aws-amplify/core';

export const login = ({ dispatch }, data) => {
  return authService.login(data)
    .then(async (response) => {
      if (response.signInUserSession) {
        await dispatch('setToken', response.signInUserSession.idToken.jwtToken);
        await dispatch('loadUser');
      }
      return response;
    });
};

export const forgetPassword = ({ dispatch, commit }, username) => {
  return authService.forgotPassword(username).then();
};

export const forgotPasswordSubmit = ({ dispatch, commit }, { email, code, password }) => {
  return authService.forgotPasswordSubmit(email, code, password).then();
};

export const completeNewPassword = ({ dispatch, commit }, { cognitoUser, password }) => {
  return authService.completeNewPassword(cognitoUser, password)
    .then(async (response) => {
      await dispatch('setToken', response.signInUserSession.idToken.jwtToken) // keep promise chain
        .then(() => dispatch('loadUser'));
      return response;
    });
};

export const logout = ({ dispatch, commit }) => {
  dispatch('setToken', '');
  commit('logout');
  authService.logOut();
  return new Promise(resolve => {
    vm.$disconnect();
    resolve(vm.$router.push('/'));
  });
};

export const setUser = ({ commit }, user) => {
  // Commit the mutations
  commit('setUser', user);

  Promise.resolve(user); // keep promise chain
};

export const setToken = ({ commit }, payload) => {
  // prevent if payload is a object
  const token = (isEmpty(payload)) ? null : payload.token || payload;

  // Commit the mutations
  commit('setToken', token);
  http.setToken(token);
  localStorage.setItem(config.userTokenStorageKey, token || '');

  return Promise.resolve(token); // keep promise chain
};

export const checkUserToken = async ({ dispatch, state }) => {
  // If the token exists then all validation has already been done
  if (state.auth.token) {
    return Promise.resolve(state.auth.token);
  }

  /**
   * Token does not exist yet
   * - Recover it from localstorage
   * - Recover also the user, validating the token also
   */
  const token = localStorage.getItem(config.userTokenStorageKey);

  if (isEmpty(token)) {
    return Promise.resolve(null);
  }
  // Put the token in the vuex store
  await dispatch('setToken', token);
  return dispatch('loadUser');
};

export const loadUser = async ({ dispatch }) => {
  let userData = await authService.me();
  await dispatch('setUser', userData);
  await dispatch('setAWSCredentials');
  await dispatch('connectWebsocket');
  dispatch('initStore');
};

export const connectWebsocket = ({ state }) => {
  const accessInfo = {
    access_key: state.auth.aws.accessKeyId,
    secret_key: state.auth.aws.secretAccessKey,
    session_token: state.auth.aws.sessionToken
  };
  const serviceInfo = { region: 'us-west-2', service: 'execute-api' };
  const url = Signer.signUrl(vm.$config.WEB_SOCKET_URL, accessInfo, serviceInfo);
  vm.$connect(url, { protocol: localStorage.getItem('jwt-token') });
};

export const setAWSCredentials = async ({ commit, dispatch }) => {
  const data = await authService.credentials();
  if (!data.authenticated) {
    dispatch('logout');
    return Promise.reject(data);
  }
  await commit('setAWSCredentials', data);
};

export const initStore = ({ dispatch, getters }) => {
  dispatch('loadRobotTypes');
  dispatch('robots/load');
  dispatch('fetchcores/load');
  dispatch('manufacturers/load');
  if (getters.isFetch || getters.isAdmin) {
    dispatch('loadVpnCustomers');
    dispatch('loadZerotierNetworks');
  }
  if (getters.isAdmin) {
    dispatch('users/load');
    dispatch('events/load');
    dispatch('resources/load');
  }
};

export const loadRobotTypes = async ({ commit }) => {
  const data = await robotTypeService.get();
  commit('setRobotTypes', data);
};

export const loadVpnCustomers = async ({ commit }) => {
  const data = await vpnCustomerService.get();
  commit('setVpnCustomers', data);
};

export const loadZerotierNetworks = async ({ commit }) => {
  const data = await zerotierNetworksService.get();
  commit('setZerotierNetworks', data);
};
