import { http } from '@/plugins/http';
import { getData } from '@/utils/get';

class Robot {
  get = () => http.post('robots', []).then(getData);

  create = (data) => http.post('register', data).then(getData);

  baseBurnIn = (data) => http.post('/base-burn-in', data).then(getData);

  burnIn = (data) => http.post('/burn-in', data).then(getData);

  reset = (data) => http.post('/reset', data).then(getData);

  standby = (data) => http.post('/standby', data).then(getData);

  allocate = (data) => http.post('/allocate', data).then(getData);

  ship = (data) => http.post('/ship', data).then(getData);

  remove = (data) => http.post('/retire', data).then(getData);

  installation = (data) => http.post('/installation', data).then(getData);
}

export default new Robot();
