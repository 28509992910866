import { http } from '@/plugins/http';
import { getData } from '@/utils/get';

export const BURNIN_ID = 1;
export const BURNIN_NAME = 'cm-burnin';

class Fetchcore {
  get = () => http.post('fetchcores', []).then(getData);

  deauthorize = (data) => http.post('deauthorize', data).then(getData);
}

export default new Fetchcore();
