<template>
  <b-badge :variant="groupColor" class="mx-1" pill>{{ group }}</b-badge>
</template>

<script>
export default {
  props: ['group'],
  computed: {
    groupColor () {
      switch (this.group) {
        case 'admin':
          return 'primary';
        case 'fetch':
          return 'secondary';
        case 'cm-test':
          return 'warning';
        default:
          return 'default';
      }
    }
  }
};
</script>
