import AwsAuth from '@aws-amplify/auth';

class Auth {
  me = () => AwsAuth.currentAuthenticatedUser();

  login = (payload) => AwsAuth.signIn(payload.username, payload.password);

  credentials = () => AwsAuth.currentUserCredentials();

  logOut = () => AwsAuth.signOut();

  forgotPassword = (username) => AwsAuth.forgotPassword(username);

  forgotPasswordSubmit = (username, code, password) => AwsAuth.forgotPasswordSubmit(username, code, password);

  completeNewPassword = (user, password) => AwsAuth.completeNewPassword(user, password);

  update = async (attributes) => {
    const user = await AwsAuth.currentAuthenticatedUser();
    return AwsAuth.updateUserAttributes(user, attributes);
  }

  changePassword = async (oldPassword, newPassword) => {
    const user = await AwsAuth.currentAuthenticatedUser();
    return AwsAuth.changePassword(user, oldPassword, newPassword);
  }
}
export default new Auth();
