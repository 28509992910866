export default {
  BASE_URL: process.env.VUE_APP_BASE_URL,
  API_URL: process.env.VUE_APP_BASE_URL + '/',
  WEB_SOCKET_URL: process.env.VUE_APP_WEB_SOCKET_URL,
  userTokenStorageKey: 'jwt-token',
  IDENTITY_POOL_ID: process.env.VUE_APP_IDENTITY_POOL_ID,
  AWS_REGION: process.env.VUE_APP_AWS_REGION,
  USER_POOL_ID: process.env.VUE_APP_USER_POOL_ID,
  USER_POOL_WEB_CLIENT_ID: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID,
  CM_GROUP_IAM_ROLE_ARN: process.env.VUE_APP_CM_GROUP_IAM_ROLE_ARN
};
