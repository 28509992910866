import Vue from 'vue';
import Vuex from 'vuex';
import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

import state from './state';

import robots from './modules/robots';
import fetchcores from './modules/fetchcores';
import users from './modules/users';
import manufacturers from './modules/manufacturers';
import events from './modules/events';
import resources from './modules/resources';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  modules: { robots, fetchcores, users, manufacturers, events, resources },
  strict: debug
});
