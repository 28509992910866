import store from '../../store';
import axios from 'axios';
import interceptors from './interceptors';
import config from '@/config';
import { aws4Interceptor } from 'aws4-axios';

export const http = axios.create({
  baseURL: config.API_URL,
  headers: {
    'content-type': 'application/json'
  }
});

http.interceptors.request.use(async (config) => {
  return aws4Interceptor({
    region: 'us-west-2',
    service: 'execute-api'
  },
  {
    accessKeyId: store.state.auth.aws.accessKeyId,
    secretAccessKey: store.state.auth.aws.secretAccessKey,
    sessionToken: store.state.auth.aws.sessionToken
  })(config).then(res => {
    delete (res.headers.Host);
    delete (res.headers['Content-Length']);
    return res;
  });
});

/**
 * Helper method to set the header with the token
 */
export function setToken (token) {
  http.defaults.headers['User-Token'] = `${token}`;
}

export default function install (Vue, { store, router }) {
  interceptors(http, store, router);
  Object.defineProperty(Vue.prototype, '$http', {
    get () {
      return http;
    }
  });
}
