import Vue from 'vue';
import userService from '@/services/user';

export default {
  namespaced: true,
  state: {
    all: [],
    groups: []
  },
  actions: {
    async load ({ commit, dispatch }) {
      const groups = (await userService.getGroupsWithUsers());
      let users = [];
      groups.map(group => {
        group.Users.map(user => {
          user = userService.transformToUser(user);

          user.groups = [group.GroupName];
          const item = users.find(el => el.username === user.username);
          if (item) {
            item.groups.push(group.GroupName);
            Object.assign(user, item);
          } else {
            users.push(user);
          }
        });
      });
      commit('setAll', users);
      commit('setGroups', groups);
      dispatch('loadAll');
    },
    async loadAll ({ commit }) {
      const allUsers = (await userService.getAll()).Users;
      allUsers.map(el => {
        const user = userService.transformToUser(el);
        commit('updateUser', user);
      });
    }
  },
  mutations: {
    setAll (state, users) {
      state.all = users;
    },
    setGroups (state, groups) {
      state.groups = groups;
    },
    updateUser (state, user) {
      const item = state.all.find(el => el.username === user.username);
      if (item) {
        Object.assign(item, user);
      } else {
        state.all.push(user);
      }
    },
    removeUser (state, user) {
      Vue.delete(state.all, state.all.findIndex(el => el.username === user.username));
    },
    removeGroup (state, group) {
      Vue.delete(state.groups, state.groups.findIndex(el => el.GroupName === group));
    },
    updateGroup (state, group) {
      const item = state.groups.find(el => el.GroupName === group.GroupName);
      if (item) {
        Object.assign(item, group);
      } else {
        state.groups.push(group);
      }
    }
  },
  getters: {
    all ({ all }) {
      return all;
    }
  }
};
