import store from '../store';
import middlewarePipeline from './middlewarePipeline';

const beforeEach = (to, from, next) => {
  store.dispatch('checkUserToken')
    .then(() => {
      let meta = to.meta;
      if (!meta.middleware && to.matched.length) {
        meta = to.matched[0].meta;
      }
      if (!meta.middleware) {
        return next();
      }

      // run middleware
      const middleware = meta.middleware;
      const context = { to, from, next, store };
      return middleware[0]({
        ...context,
        nextMiddleware: middlewarePipeline(context, middleware, 1)
      });
    })
    .catch(() => {
      next('/');
    });
};

export default beforeEach;
