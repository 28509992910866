import fetchcoreService from '@/services/fetchcore';
import vm from '@/main';
export default {
  namespaced: true,
  state: {
    all: []
  },
  actions: {
    async load ({ commit }) {
      commit('setAll', (await fetchcoreService.get()));
    }
  },
  mutations: {
    setAll (state, fetchcores) {
      state.all = vm.$helpers.sort(fetchcores, 'fetchcore_name');
    },
    updateFetchcore (state, fetchcore) {
      const item = state.all.find(el => el.fetchcore_id === fetchcore.fetchcore_id);
      if (item) {
        Object.assign(item, fetchcore);
      } else {
        state.all.push(fetchcore);
      }
    }
  },
  getters: {
    all ({ all }) {
      return all;
    }
  }
};
