import store from '@/store';
import config from '@/config';

import CognitoIdentityServiceProvider from 'aws-sdk/clients/cognitoidentityserviceprovider/';

class User {
  getClient = (store) => {
    return new CognitoIdentityServiceProvider({
      region: config.AWS_REGION,
      credentials: store.state.auth.aws
    });
  }

  getGroupsWithUsers = async () => {
    const client = this.getClient(store);
    let groups = await client.listGroups({ UserPoolId: config.USER_POOL_ID }).promise();
    return Promise.all(groups.Groups.map(async (groupEntity) => {
      const data = await this.getByGroup(groupEntity.GroupName);
      groupEntity.Users = data.Users;
      return groupEntity;
    }));
  }

  getByGroup = async (group) => {
    const client = this.getClient(store);
    const params = {
      GroupName: group,
      UserPoolId: config.USER_POOL_ID
    };
    // eslint-disable-next-line no-return-await
    return await client.listUsersInGroup(params).promise();
  }

  getAll = async () => {
    const client = this.getClient(store);
    const params = {
      UserPoolId: config.USER_POOL_ID
    };
    // eslint-disable-next-line no-return-await
    return await client.listUsers(params).promise();
  }

  create = async (user) => {
    const client = this.getClient(store);
    let data = this.transformToData(user);
    data.UserPoolId = config.USER_POOL_ID;
    // eslint-disable-next-line no-return-await
    const userData = await client.adminCreateUser(data).promise();
    return this.transformToUser(userData.User);
  }

  update = async (user) => {
    const client = this.getClient(store);
    let data = this.transformToData(user);
    data.UserPoolId = config.USER_POOL_ID;
    // eslint-disable-next-line no-return-await
    return await client.adminUpdateUserAttributes(data).promise();
  }

  remove = async (user) => {
    const client = this.getClient(store);
    const data = {
      UserPoolId: config.USER_POOL_ID,
      Username: user.username
    };
    // eslint-disable-next-line no-return-await
    return await client.adminDeleteUser(data).promise();
  }

  disable = async (user) => {
    const client = this.getClient(store);
    const data = {
      UserPoolId: config.USER_POOL_ID,
      Username: user.username
    };
    // eslint-disable-next-line no-return-await
    return await client.adminDisableUser(data).promise();
  }

  enable = async (user) => {
    const client = this.getClient(store);
    const data = {
      UserPoolId: config.USER_POOL_ID,
      Username: user.username
    };
    // eslint-disable-next-line no-return-await
    return await client.adminEnableUser(data).promise();
  }

  resendConfirmationCode = async (user) => {
    const client = this.getClient(store);
    const data = {
      ClientId: config.USER_POOL_WEB_CLIENT_ID,
      Username: user.username
      // SecretHash: 'new_string'
    };
    // eslint-disable-next-line no-return-await
    return await client.resendConfirmationCode(data).promise();
  }

  attachGroup = async (username, group) => {
    const client = this.getClient(store);
    const data = {
      UserPoolId: config.USER_POOL_ID,
      Username: username,
      GroupName: group
    };
    // eslint-disable-next-line no-return-await
    return await client.adminAddUserToGroup(data).promise();
  }

  detachGroup = async (username, group) => {
    const client = this.getClient(store);
    const data = {
      UserPoolId: config.USER_POOL_ID,
      Username: username,
      GroupName: group
    };
    // eslint-disable-next-line no-return-await
    return await client.adminRemoveUserFromGroup(data).promise();
  }

  createGroup = async (group) => {
    const client = this.getClient(store);
    const data = {
      UserPoolId: config.USER_POOL_ID,
      GroupName: group.name,
      Description: group.description,
      Precedence: group.precedence,
      RoleArn: group.role
    };
    // eslint-disable-next-line no-return-await
    return await client.createGroup(data).promise();
  }

  deleteGroup = async (group) => {
    const client = this.getClient(store);
    const data = {
      UserPoolId: config.USER_POOL_ID,
      GroupName: group
    };
    // eslint-disable-next-line no-return-await
    return await client.deleteGroup(data).promise();
  }

  transformToData (user) {
    let data = {
      Username: user.username,
      TemporaryPassword: user.password,
      UserAttributes: [
        {
          Name: 'email_verified',
          Value: user.email_verified.toString()
        }
      ]
    };
    if (!user.created_at && user.email) {
      data.UserAttributes.push({
        Name: 'email',
        Value: user.email
      });
    }
    if (user.phone_number) {
      data.UserAttributes.push({
        Name: 'phone_number',
        Value: user.phone_number
      });
    }
    if (user.phone_number_verified) {
      data.UserAttributes.push({
        Name: 'phone_number_verified',
        Value: user.phone_number_verified.toString()
      });
    }
    return data;
  }

  transformToUser (data) {
    const email = data.Attributes.find(el => el.Name === 'email');
    const phoneNumber = data.Attributes.find(el => el.Name === 'phone_number');
    const emailVerified = data.Attributes.find(el => el.Name === 'email_verified');
    const name = data.Attributes.find(el => el.Name === 'name');
    return {
      username: data.Username,
      name: name ? name.Value : null,
      enabled: data.Enabled,
      status: data.UserStatus,
      created_at: data.UserCreateDate,
      last_modified_at: data.UserLastModifiedDate,
      email: email ? email.Value : null,
      phone_number: phoneNumber ? phoneNumber.Value : null,
      email_verified: emailVerified ? emailVerified.Value === 'true' : false
    };
  }
}

export default new User();
