<template>
  <label class="switcher">
    <input type="checkbox" class="switcher-input" v-model="checked"  @change="$emit('input', checked)">
    <span class="switcher-indicator">
      <span class="switcher-yes"></span>
      <span class="switcher-no"></span>
    </span>
    <span class="switcher-label">{{ title }}</span>
  </label>
</template>

<script>
export default {
  data () {
    return {
      checked: this.value
    };
  },
  name: 'switcher',
  props: {
    value: {
      type: Boolean
    },
    title: {
      default: ''
    }
  },
  watch: {
    value () {
      this.checked = this.value;
    }
  }
};
</script>
