<template>
  <b-badge :variant="eventColor" class="mx-1" pill>{{ event }}</b-badge>
</template>

<script>
export default {
  props: ['event'],
  computed: {
    eventColor () {
      switch (this.event) {
        case 'register':
          return 'success';
        case 'burn-in':
          return 'warning';
        case 'standby':
          return 'info';
        case 'reset':
          return 'default';
        case 'deauthorize':
          return 'danger';
        default:
          return 'default';
      }
    }
  }
};
</script>
