import { http } from '@/plugins/http';
import { getData } from '@/utils/get';

class Resource {
  get = () => http.post('fetchcore-resources ', []).then(getData);

  update = (resource) => http.post('update_fetchcore-resource', resource).then(getData);
}

export default new Resource();
