<template>
  <router-view/>
</template>

<style src="@/components/vue-notification/vue-notification.scss" lang="scss"></style>
<style src="@/assets/styles/style.scss" lang="scss"></style>
<script>
export default {
  metaInfo: {
    titleTemplate: '%s | Fetch Robotics'
  },
  data () {
    return {
      showedOffline: false,
      reconnectionAttempts: 5
    };
  },
  async created () {
    this.$options.sockets.onmessage = (data) => {
      data = JSON.parse(data.data);
      if (data.event_name) {
        this.$store.commit('events/addEvent', data);
      }
      if (data.device_id) {
        this.$store.commit('robots/updateRobot', data);
      }
    };
    this.$options.sockets.onerror = (data) => console.info(data);
    this.$options.sockets.onopen = () => {
      console.info('Connection is setup!');
    };
    this.$options.sockets.onclose = (data) => {
      if (this.isLogged) {
        this.reconnectionAttempts--;
        if (this.reconnectionAttempts > 0) {
          this.$store.dispatch('connectWebsocket');
        } else {
          this.showOffline();
          console.error('Connection is closed!');
        }
      }
    };
    const self = this;
    window.addEventListener('offline', function () {
      self.showOffline();
    });
    window.addEventListener('online', function () {
      this.showedOffline = false;
      self.$toasted.clear();
    });
  },
  methods: {
    showOffline () {
      if (!this.showedOffline) {
        this.showedOffline = true;
        this.$toasted.show('<i class="ion ion-md-cloud-outline mr-2"></i> You are offline!', {
          position: 'bottom-right',
          action: [{
            text: 'Reload',
            onClick: (e, toastObject) => {
              document.location.reload();
            }
          }]
        });
      }
    }
  }
};
</script>
