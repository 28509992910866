import guest from '@/router/middlewares/guest';

export const Auth = r => require.ensure([], () => r(require('./auth')), 'auth-bundle');
export const Login = r => require.ensure([], () => r(require('./login')), 'login-bundle');
export const ForgetPassword = r => require.ensure([], () => r(require('./forget-password')), 'forget-password-bundle');
export const ConfirmResetPassword = r => require.ensure([], () => r(require('./confirm-reset-password')), 'confirm-reset-password-bundle');

export default [
  {
    path: '/',
    component: Auth,
    children: [
      {
        path: '/',
        name: 'login',
        component: Login,
        meta: {
          middleware: [guest]
        }
      },
      {
        path: 'forget-password',
        name: 'forget-password',
        component: ForgetPassword
      },
      {
        path: 'confirm-reset-password',
        name: 'confirm-reset-password',
        component: ConfirmResetPassword
      }

    ]
  }
];
