export const Helpers = {
  install (Vue, options) {
    Vue.prototype.$helpers = {
      clone (object) {
        if (!object) {
          return null;
        }
        return JSON.parse(JSON.stringify(object));
      },
      onlyUnique (value, index, self) {
        return self.indexOf(value) === index;
      },
      /**
       * objSort(array, 'name') --> sort by name (ascending, case in-sensitive)
       * objSort(array, ['name', true]) --> sort by name (descending, case in-sensitive)
       * objSort(array, 'name', true) --> sort by name then year (ascending, case sensitive)
       * objSort(array, 'name', 'year') --> sort by name then year (both ascending, case in-sensitive)
       * objSort(array, 'name', ['year', true]) --> sort by name (ascending) then year
       * @returns {*}
       */
      sort () {
        var args = arguments;
        var array = args[0];
        var caseSensitive; var keysLength; var key; var desc; var a; var b; var i;

        if (typeof arguments[arguments.length - 1] === 'boolean') {
          caseSensitive = arguments[arguments.length - 1];
          keysLength = arguments.length - 1;
        } else {
          caseSensitive = false;
          keysLength = arguments.length;
        }

        return array.sort(function (obj1, obj2) {
          for (i = 1; i < keysLength; i++) {
            key = args[i];
            if (typeof key !== 'string') {
              desc = key[1];
              key = key[0];
              a = obj1[args[i][0]];
              b = obj2[args[i][0]];
            } else {
              desc = false;
              a = obj1[args[i]];
              b = obj2[args[i]];
            }

            if (caseSensitive === false && typeof a === 'string' && b) {
              a = a.toLowerCase();
              b = b.toLowerCase();
            }

            if (!desc) {
              if (a < b) return -1;
              if (a > b) return 1;
            } else {
              if (a > b) return -1;
              if (a < b) return 1;
            }
          }
          return 0;
        });
      },
      search (search, items, keys) {
        if (!search) {
          return items;
        }
        const val = search.toLowerCase();
        return items.filter(d => {
          return Object.keys(d)
            .filter(k => keys.includes(k))
            .map(k => String(d[k]))
            .join('|')
            .toLowerCase()
            .indexOf(val) !== -1 || !val;
        });
      },
      downloadFile (url, fileName) {
        let element = document.createElement('a');
        element.setAttribute('href', url);
        if (fileName) {
          element.setAttribute('download', fileName);
        }

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      },
      stringToColour (str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let colour = '#';
        for (let i = 0; i < 3; i++) {
          let value = (hash >> (i * 8)) & 0xFF;
          colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
      },
      groupBy (data, field) {
        return data.filter(el => el[field]).reduce((p, c) => {
          const name = c[field];
          if (!p.hasOwnProperty(name)) {
            p[name] = 0;
          }
          p[name]++;
          return p;
        }, {});
      }
    };
  }
};
