import vm from '@/main';

let currentLoader = null;
let loaders = [];
export const hideLoader = () => {
  loaders.pop();
  if (!loaders.length) {
    setTimeout(() => {
      if (currentLoader) {
        currentLoader.hide();
      }
      currentLoader = null;
    }, 300);
  }
};

export default (http, store) => {
  http.interceptors.request.use(function (config) {
    if (currentLoader == null && !config.silent) {
      currentLoader = vm.$loading.show();
    }
    return config;
  }, (error) => {
    hideLoader();

    return Promise.reject(error);
  });
  http.interceptors.response.use(
    response => {
      hideLoader();

      return Promise.resolve(response);
    }, (error) => {
      hideLoader();

      const response = error.response;

      if (response && response.data.message) {
        vm.$notify({ type: 'error', title: 'Error', text: response.data.message });
      }

      return Promise.reject(error);
    });
};
