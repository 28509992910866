import eventService from '@/services/event';

export default {
  namespaced: true,
  state: {
    all: []
  },
  actions: {
    async load ({ commit }) {
      commit('setAll', (await eventService.get()));
    }
  },
  mutations: {
    setAll (state, fetchcores) {
      state.all = fetchcores;
    },
    addEvent (state, event) {
      event.new = true;
      state.all.push(event);
    }
  },
  getters: {
    all ({ all }) {
      return all;
    }
  }
};
