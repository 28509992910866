import config from '@/config';

export default {
  computed: {
    $config () {
      return config;
    },
    isLogged () {
      return this.$store.getters.logged;
    },
    isAdmin () {
      return this.$store.getters.isAdmin;
    },
    isFetch () {
      return this.$store.getters.isFetch;
    },
    currentUser () {
      return this.$store.getters.currentUser;
    }
  }
};
