import dashboard from './dashboard/routes';
import home from './home/routes';
import auth from './auth/routes';
import profile from './profile/routes';
import robots from './robots/routes';
import fetchcores from './fetchcores/routes';
import resources from './resources/routes';
import users from './users/routes';
import manufacturers from './manufacturers/routes';
import activities from './activities/routes';
import pages from './pages/routes';

import isAuth from '@/router/middlewares/auth';

export const BaseLayout = r => require.ensure([], () => r(require('@/layouts/layout')));
export const BlankLayout = r => require.ensure([], () => r(require('@/layouts/blank')));

export default [

  {
    path: '/dashboard',
    component: BaseLayout,
    children: [
      ...dashboard,
      ...home,
      ...profile,
      ...robots,
      ...fetchcores,
      ...resources,
      ...users,
      ...manufacturers,
      ...activities
    ],
    meta: {
      middleware: [isAuth]
    }
  },
  {
    path: '/',
    component: BlankLayout,
    children: [
      ...auth,
      ...pages
    ]
  }
];
