import config from '@/config';
import Auth from '@aws-amplify/auth';

Auth.configure({
  aws_project_region: config.AWS_REGION,
  aws_cognito_identity_pool_id: config.IDENTITY_POOL_ID,
  aws_cognito_region: config.AWS_REGION,
  aws_user_pools_id: config.USER_POOL_ID,
  aws_user_pools_web_client_id: config.USER_POOL_WEB_CLIENT_ID,
  oauth: {}
});
