import vm from '@/main';

export const setToken = (state, value) => {
  state.auth.token = value;
};
export const setUser = (state, user) => {
  state.auth.user = {
    username: user.username,
    name: user.attributes.name,
    phone_number: user.attributes.phone_number,
    email: user.attributes.email,
    email_verified: user.attributes.email_verified,
    groups: user.signInUserSession.idToken.payload['cognito:groups']
  };
  state.auth.isAuthorized = true;
};
export const setAWSCredentials = (state, data) => {
  state.auth.aws = data;
};
export const setRobotTypes = (state, data) => {
  state.robotTypes = vm.$helpers.sort(data, 'device_type');
};
export const setVpnCustomers = (state, data) => {
  state.vpnCustomers = vm.$helpers.sort(data, 'vpn_customer_name');
};
export const setZerotierNetworks = (state, data) => {
  state.zerotierNetworks = vm.$helpers.sort(data, 'zerotier_network_name');
};
export const setSearch = (state, search) => {
  state.search = search;
};
export const setDevMode = (state, mode) => {
  state.devMode = mode;
};
export const resetSearch = (state) => {
  state.search = null;
};
export const logout = (state) => {
  state.auth.user = {};
  state.auth.token = null;
  state.auth.isAuthorized = false;
};
