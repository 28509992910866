import robotService from '@/services/robot';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    all: []
  },
  actions: {
    async load ({ commit }) {
      commit('setAll', (await robotService.get()));
    }
  },
  mutations: {
    setAll (state, robots) {
      state.all = robots;
    },
    updateRobot (state, robot) {
      const item = state.all.find(el => el.device_id === robot.device_id);
      if (item) {
        Object.assign(item, robot);
      } else {
        state.all.push(robot);
      }
    },
    removeRobot (state, robot) {
      Vue.delete(state.all, state.all.findIndex(el => el.device_id === robot.device_id));
    }
  },
  getters: {
    all ({ all }) {
      return all;
    }
  }
};
