<template>
  <b-card-body class="pt-0 pb-3" v-if="items.length">
    <div class="row">
      <div class="col-auto text-sm-left text-center pt-3">
        <span class="text-muted" v-if="items.length">
          Page {{ pagination.currentPage }} of {{ Math.ceil(items.length / pagination.perPage) }}
        </span>
      </div>
      <div class="col-sm pt-3">
        <b-pagination class="justify-content-center justify-content-sm-end m-0"
                      v-if="items.length"
                      v-model="pagination.currentPage"
                      :total-rows="items.length"
                      :per-page="pagination.perPage"
                      size="sm" />
      </div>
    </div>
  </b-card-body>
</template>

<script>
export default {
  name: 'pagination',
  props: {
    items: {
      type: Array,
      required: true
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          currentPage: 1,
          paePage: 15
        };
      }
    }
  }
};
</script>
