<template>
  <div v-if="password">
    <span class="text-danger" v-if="!passwordValid">The password is insecure</span>
    <div class="password-validation mt-2">
      <div class="d-flex align-items-center w-100" :class="{ is_valid: containsEightCharacters }">
        <i class="d-block far fa-check-circle ui-w-20 mr-1"></i>At least 8 characters long
      </div>
      <div class="d-flex align-items-center w-100" :class="{ is_valid: containsNumber }">
        <i class="d-block far fa-check-circle ui-w-20 mr-1"></i>One number
      </div>
      <div class="d-flex align-items-center w-100" :class="{ is_valid: containsLowercase }">
        <i class="d-block far fa-check-circle ui-w-20 mr-1"></i>One lowercase character
      </div>
      <div class="d-flex align-items-center w-100" :class="{ is_valid: containsUppercase }">
        <i class="d-block far fa-check-circle ui-w-20 mr-1"></i>One uppercase character
      </div>
      <div class="d-flex align-items-center w-100" :class="{ is_valid: containsSpecialCharacter }">
        <i class="d-block far fa-check-circle ui-w-20 mr-1"></i>One special character
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['password'],
  computed: {
    containsEightCharacters () {
      return this.password.length >= 8;
    },
    containsNumber () {
      return /\d/.test(this.password);
    },
    containsLowercase () {
      return /[a-z]/.test(this.password);
    },
    containsUppercase () {
      return /[A-Z]/.test(this.password);
    },
    containsSpecialCharacter () {
      return /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(this.password);
    },
    passwordValid () {
      if (!this.password) {
        return;
      }
      const valid = this.containsEightCharacters &&
                this.containsNumber &&
                this.containsLowercase &&
                this.containsUppercase &&
                this.containsSpecialCharacter;
      if (valid) {
        this.$emit('validated', true);
      } else {
        this.$emit('validated', false);
      }
      return valid;
    }
  }
};
</script>

<style scoped>

</style>
