import admin from '@/router/middlewares/admin';

export const Index = r => require.ensure([], () => r(require('./index')), 'activities-bundle');

export default [
  {
    path: 'activities',
    name: 'activities',
    component: Index,
    meta: {
      middleware: [admin]
    }
  }
];
