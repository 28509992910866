import manufacturerService from '@/services/manufacturer';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    all: []
  },
  actions: {
    async load ({ commit }) {
      commit('setAll', (await manufacturerService.get()));
    }
  },
  mutations: {
    setAll (state, manufacturer) {
      state.all = manufacturer;
    },
    updateManufacturer (state, manufacturer) {
      const item = state.all.find(el => el.manufacturer_id === manufacturer.manufacturer_id);
      if (item) {
        Object.assign(item, manufacturer);
      } else {
        state.all.push(manufacturer);
      }
    },
    removeManufacturer (state, manufacturer) {
      Vue.delete(state.all, state.all.findIndex(el => el.manufacturer_id === manufacturer.manufacturer_id));
    }
  },
  getters: {
    all ({ all }) {
      return all;
    }
  }
};
