import resourceService from '@/services/resource';
import vm from '@/main';
export default {
  namespaced: true,
  state: {
    all: []
  },
  actions: {
    async load ({ commit }) {
      commit('setAll', (await resourceService.get()));
    }
  },
  mutations: {
    setAll (state, resources) {
      state.all = vm.$helpers.sort(resources, 'resource_name');
    },
    updateResource (state, resource) {
      const item = state.all.find(el => el.fetchcore_id === resource.resource_id);
      if (item) {
        Object.assign(item, resource);
      } else {
        state.all.push(resource);
      }
    }
  },
  getters: {
    all ({ all }) {
      return all;
    }
  }
};
