export default {
  auth: {
    isAuthorized: false,
    token: localStorage.getItem('auth.token') || null,
    user: {
      groups: []
    },
    aws: {
      accessKeyId: null,
      authenticated: null,
      expiration: null,
      identityId: null,
      secretAccessKey: null,
      sessionToken: null
    }
  },
  robotTypes: [],
  vpnCustomers: [],
  zerotierNetworks: [],
  search: null,
  devMode: localStorage.getItem('dev-demo') === 'true'
};
