import { http } from '@/plugins/http';
import { getData } from '@/utils/get';

class Manufacturer {
  get = () => http.post('manufacturers', []).then(getData);

  create = (data) => http.post('update_manufacturer', data).then(getData);

  update = (data) => http.post('update_manufacturer', data).then(getData);

  remove = (data) => http.post('delete_manufacturer', data).then(getData);
}

export default new Manufacturer();
