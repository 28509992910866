import Vue from 'vue';
import VueMeta from 'vue-meta';
import { BootstrapVue } from 'bootstrap-vue';
import VueNativeSock from 'vue-native-websocket';
import Toasted from 'vue-toasted';
import App from './App.vue';
import LaddaBtn from '@/components/ladda';
import GroupLabel from '@/components/group-label';
import EventLabel from '@/components/event-label';
import PasswordPolicy from '@/components/password-policy';
import Switcher from '@/components/switcher';
import Pagination from '@/components/pagination';
import Notifications from 'vue-notification';
import router from './router';
import store from './store';
import eventBus from './plugins/eventbus';
import httpPlugin from './plugins/http';
import globalMixin from './plugins/global-mixin';
import { Helpers } from './plugins/helpers';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import './assets/styles/bootstrap.scss';
import './assets/styles/appwork.scss';
import './assets/styles/theme-corporate.scss';
import './assets/styles/colors.scss';
import './assets/styles/uikit.scss';
import './components/multiselect/vue-multiselect.scss';
import './components/vue-input-tag/vue-input-tag.scss';

require('@/plugins/aws');

Vue.component('ladda-btn', LaddaBtn);
Vue.component('group-label', GroupLabel);
Vue.component('event-label', EventLabel);
Vue.component('password-policy', PasswordPolicy);
Vue.component('switcher', Switcher);
Vue.component('pagination', Pagination);
Vue.use(Helpers);
Vue.use(Notifications);
Vue.use(BootstrapVue);
Vue.use(eventBus);
Vue.use(Loading);
Vue.use(Toasted);
Vue.use(require('vue-moment'));
Vue.use(VueMeta, { refreshOnceOnNavigation: true });
Vue.use(httpPlugin, { store, router });
Vue.use(VueNativeSock, '/', {
  connectManually: true,
  protocol: localStorage.getItem('jwt-token')
});

Vue.mixin(globalMixin);

Vue.config.productionTip = false;

export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
